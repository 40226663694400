<template>
  <div>
    <h1 class="mb-30">Шаблоны</h1>

    <NavTabsSwiper :tabs-list="tabs" />

    <router-view />
  </div>
</template>

<script>
import NavTabsSwiper from '@/shared/ui/tabs/NavTabsSwiper.vue'

export default {
  name: 'Template',

  components: {
    NavTabsSwiper
  },
  data: () => ({
    tabs: [
      {
        id: 1,
        routeName: 'TemplatePositionList',
        label: 'Позиции'
      },
      {
        id: 2,
        routeName: 'TemplateStageLineList',
        label: 'Этапы'
      }
    ]
  })
}
</script>
